
import {
  IonRow,
  IonCol,
  IonGrid,
  IonInput,
  IonSelect,
  IonTextarea,
  IonSelectOption,
} from "@ionic/vue";
import { DateTime } from "luxon";
import { useStore } from "../store";
import { defineComponent } from "vue";
import { DatePicker } from "v-calendar";

export default defineComponent({
  name: "EditParty",
  components: {
    IonRow,
    IonCol,
    IonGrid,
    IonInput,
    IonSelect,
    IonTextarea,
    IonSelectOption,
    DatePicker,
  },
  data: () => {
    return {
      store: useStore(),
      selectedTab: "event-details",
      eventDetails: {
        partyId: "",
        partyName: "",
        dateTime: "",
        guests: "",
        children: "",
        locationAddress: "",
        additionalRequirements: "",
      },
      loading: false,
      errors: {},
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DDTHH:mm:ssZZZZ",
      },
    };
  },
  computed: {
    getMyParty(): {} {
      return this.filterMyParty(
        this.store.state.user.parties.find((party: any) => {
          return party.id == this.$route.params.id;
        })
      );
    },
  },
  methods: {
    async updateSupplierStatus(value: any, supplierId: any) {
      this.errors = {};
      const data = {
        partyId: this.eventDetails.partyId,
        supplierId,
        status: value.detail.value,
      };
      try {
        await this.store.dispatch("updateSupplierStatus", data);
      } catch (e) {
        console.log("catch error on update supplier status");
        console.log(e.response);
        this.errors = e.response.data;
      }
    },
    async updateParty() {
      this.loading = true;
      this.errors = {};
      try {
        await this.store.dispatch("updateParty", this.eventDetails);
      } catch (e) {
        console.log("catch error on update party");
        console.log(e.response);
        this.errors = e.response.data;
      }
      this.loading = false;
    },
    filterMyParty(myParty: any) {
      // Set local state data
      this.eventDetails = {
        partyId: this.$route.params.id as string,
        partyName: myParty.party_name,
        dateTime: myParty.date_time,
        guests: myParty.guests,
        children: myParty.children,
        locationAddress: myParty.location_address,
        additionalRequirements: myParty.additional_requirements,
      };
      // Return original value with alterations
      return myParty;
    },
    formatDate(date: string): any {
      // 2021-08-28T14:30:52.688+01:00
      return DateTime.fromISO(date).toFormat("dd LLL yyyy");
    },
    selectTab(tabName: string) {
      this.selectedTab = tabName;
    },
    setCategoryFilter(id: number) {
      this.store.state.searchbar.text = "";
      this.store.commit("setCategoryFilter", id);
      this.$router.push({ path: "/suppliers" });
    },
    copyToClipboard(text: string) {
      const link =
        window.location.origin + "/invitation/" + encodeURIComponent(text);
      navigator.clipboard.writeText(link);
      alert("Copied the text: " + link);
    },
  },
});
